import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import { requestFavoriteSpots, sendFavoriteSpotUpdated, RECEIVE_EVENT } from './favorite_count_controller';

export default class extends Controller {
  static targets = ['add', 'remove'];

  connect() {
    requestFavoriteSpots();
    document.addEventListener(RECEIVE_EVENT, this.setState, false);
    Rails.ajax({
      url: this.path,
      type: 'GET',
      success: (ids) => {
        const event = new CustomEvent(RECEIVE_EVENT, { detail: ids || [] });
        document.dispatchEvent(event);
      },
    });
  }

  disconnect() {
    document.removeEventListener(RECEIVE_EVENT, this.setState, false);
  }

  setState = ({ detail: ids }) => {
    if (ids.includes(this.id)) {
      this.addTarget.classList.add('d-none');
      this.removeTarget.classList.remove('d-none');
    } else {
      this.addTarget.classList.remove('d-none');
      this.removeTarget.classList.add('d-none');
    }
  };

  added() {
    this.addTarget.classList.add('d-none');
    this.removeTarget.classList.remove('d-none');
    sendFavoriteSpotUpdated();
    const url = `/${this.data.get('locale')}/add_spot_to_plan/list`;
    // sendFavoriteSpotUpdated()で非同期処理後にお気に入り数が返却されるため、ポップアップ表示を少し遅らせる
    setTimeout(function getList() {
      $.ajax({
        url,
        type: 'GET',
        timeout: 3000,
      });
    }, 300);
  }

  removed() {
    this.addTarget.classList.remove('d-none');
    this.removeTarget.classList.add('d-none');
    sendFavoriteSpotUpdated();
  }

  get id() {
    return parseInt(this.data.get('id'), 10);
  }

  get locale() {
    return this.data.get('locale');
  }

  get path() {
    return this.data.get('path');
  }
}
